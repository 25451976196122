
'use strict';
const interval = setInterval(function () {
    if (document.getElementsByClassName('mainCarouselBanner')) {
        $('.mainCarouselBanner').slick({
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            variableWidth: false,
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',

        });

        clearInterval(interval);
    }
}, 100);

